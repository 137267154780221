import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useHospitalName = () => {
  const loginDetails = useSelector(({ common }) => common.authUser);
  const employeeRecords = useSelector(
    ({ addEmployee }) => addEmployee.employeeRecord
  );

  const hospitalRecords = useSelector(
    ({ addHospital }) => addHospital.hospitalsRecord
  );

  const currentHospital = useMemo(
    () =>
      hospitalRecords?.find(
        (data) => data.HsptID === loginDetails["User"]["HospitalID"]
      ),
    [hospitalRecords, loginDetails]
  );

  const currentUser = useMemo(() => loginDetails["User"], [loginDetails]);

  const userHospitalID = useMemo(() => {
    return currentUser.HospitalID;
  }, [currentUser]);

  const userDeptId = useMemo(() => {
    return currentUser.DeptId;
  }, [currentUser]);

  const userSubdeptId = useMemo(() => {
    return currentUser.subdeptId;
  }, [currentUser]);

  return {
    currentHospital,
    currentUser,
    hospitalRecords,
    employeeRecords,
    userHospitalID,
    userDeptId,
    userSubdeptId,
  };
};
