import { httpClient, httpClient3 } from "./Api";
import { message } from "antd";

const showUrl = false;

const typedResponse = (type, callbackFun, data, url) => {
  if (callbackFun) {
    if (typeof data.data == "boolean") {
      callbackFun(data.data);
    } else if (data.IsSuccessfull && data.StatusCode === 200) {
      if (type === "data") {
        if (data.dataSet) return callbackFun(data.dataSet);
        callbackFun(data.ResponseArray);
      } else if (type === "msg") {
        callbackFun(data.Message);
      }
    } else if (data.StatusCode === 404 && data.Message === "No Data Found") {
      callbackFun([]);
    } else {
      message.error(data.Message);
    }
  }
};

const oldTypedResponse = (type, callbackFun, data) => {
  if (callbackFun) {
    if (Array.isArray(data) && data?.length) {
      callbackFun(data);
    } else if (data?.Value?.table?.length) {
      callbackFun(data?.Value?.table);
    } else {
      callbackFun([]);
    }
  }
};

export const getApi = (url, callbackFun, type, getLogout, loading) => {
  if (loading) message.loading("Please Wait...", 0);
  httpClient
    .get(url)
    .then(({ data }) => {
      if (loading) message.destroy();
      typedResponse(type, callbackFun, data, url);
    })
    .catch(function (error) {
      onErrorCatch(error, loading, getLogout, url);
    });
};

// Only Need to Call It When we work with old API Controllers
export const getOldApi = (url, callbackFun, type, getLogout, loading) => {
  if (loading) message.loading("Please Wait...", 0);
  httpClient
    .get(url)
    .then(({ data }) => {
      if (loading) message.destroy();
      oldTypedResponse(type, callbackFun, data);
    })
    .catch(function (error) {
      onErrorCatch(error, loading, getLogout, url);
    });
};

export const getBooleanApi = (url, callbackFun, type, getLogout, loading) => {
  if (loading) message.loading("Please Wait...", 0);
  httpClient
    .get(url)
    .then((isResponse) => {
      typedResponse(type, callbackFun, isResponse, url);
    })
    .catch(function (error) {
      onErrorCatch(error, loading, getLogout, url);
    });
};

export const postApi = (
  url,
  body,
  callbackFun,
  type,
  getLogout,
  loading,
  isMultiPart
) => {
  if (loading) message.loading("Please Wait...", 0);

  if (isMultiPart && body) {
    httpClient3
      .post(url, body)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  } else if (body) {
    httpClient
      .post(url, body)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  } else {
    httpClient
      .post(url)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  }
};

export const putApi = (
  url,
  body,
  callbackFun,
  type,
  getLogout,
  loading,
  showSuccess
) => {
  if (loading) message.loading("Please Wait...", 0);
  if (body) {
    httpClient
      .put(url, body)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  } else {
    httpClient
      .put(url)
      .then(({ data }) => {
        if (loading) message.destroy();
        typedResponse(type, callbackFun, data, url);
      })
      .catch(function (error) {
        onErrorCatch(error, loading, getLogout, url);
      });
  }
};

export const deleteApi = (url, callbackFun, type, getLogout, loading) => {
  if (loading) message.loading("Please Wait...", 0);
  httpClient
    .delete(url)
    .then(({ data }) => {
      if (loading) message.destroy();
      typedResponse(type, callbackFun, data, url);
    })
    .catch(function (error) {
      onErrorCatch(error, loading, getLogout, url);
    });
};

const onErrorCatch = (error, loading, getLogout, url) => {
  if (
    error.response.data.message ===
      "Session has expired. Please log in again." ||
    error.response.data.message === "Token has expired."
  ) {
    message.warn(error.response.data.message);
    getLogout();
  }
  if (loading) message.destroy();
  // if (showUrl) message.error(`url: ${url}, message: ${error.message}`);
  // if (!showUrl) message.error(error.message);
};
