import { useState } from "react";
import { httpClient } from "../Api";
import { message } from "antd";
import { postApi } from "../Axios_Dev";

export const useTemplateRetrieval = () => {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError("");
    setSuccessMessage("");
  };

  const fetchSuccess = (message) => {
    setLoading(false);
    setError("");
    setSuccessMessage(message);
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
    setSuccessMessage("");
  };

  const getAssignedTemplates = (callbackFun) => {
    fetchStart();
    httpClient
      .get("DoctorTreatment/PatientEMR/GetAssignTemplate")
      .then(({ data }) => {
        fetchSuccess(data);
        if (data != "No Record Found" && callbackFun) {
          callbackFun(data["Value"]["table"]);
        } else if (callbackFun) {
          callbackFun([]);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getAssignedMainGroup = (tempId, callbackFun) => {
    fetchStart();
    httpClient
      .get(`DoctorTreatment/PatientEMR/GetAssignMainGroup/${tempId}`)
      .then(({ data }) => {
        fetchSuccess(data);
        if (data != "No Record Found" && callbackFun) {
          callbackFun(data["Value"]["table"]);
        } else if (callbackFun) {
          callbackFun([]);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getAssignedMainHeading = (tempId, mainGroupId, callbackFun) => {
    fetchStart();
    httpClient
      .get(
        `DoctorTreatment/PatientEMR/GetAssignMainHeading/${tempId},${mainGroupId}`
      )
      .then(({ data }) => {
        fetchSuccess(data);
        if (data != "No Record Found" && callbackFun) {
          callbackFun(data);
        } else if (callbackFun) {
          callbackFun([]);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getAssignedHeading = (
    tempId,
    mainGroupId,
    mainHeadingId,
    callbackFun
  ) => {
    fetchStart();
    httpClient
      .get(
        `DoctorTreatment/PatientEMR/GetAssignHeadingID/${tempId},${mainGroupId},${mainHeadingId}`
      )
      .then(({ data }) => {
        fetchSuccess(data);
        if (data != "No Record Found" && callbackFun) {
          callbackFun(data["Value"]["table"]);
        } else if (callbackFun) {
          callbackFun([]);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getAssignedElement = (
    tempId,
    mainGroupId,
    mainHeadingId,
    headingId,
    callbackFun
  ) => {
    message.loading("Please Wait...");
    httpClient
      .get(
        `DoctorTreatment/PatientEMR/GetAssignElement/${tempId},${mainGroupId},${mainHeadingId},${headingId}`
      )
      .then(({ data }) => {
        message.destroy();
        if (data != "No Record Found" && callbackFun) {
          callbackFun(data);
        } else if (callbackFun) {
          callbackFun([]);
        }
      })
      .catch(function (error) {
        message.destroy();
        message.error(error.message);
        console.log(error.message);
      });
  };

  const addPatientEMR = (payload, callbackFun) => {
    fetchStart();
    httpClient
      .post("DoctorTreatment/PatientEMR/Post", payload)
      .then(({ data }) => {
        fetchSuccess(data);
        if (data["Value"]["msg"] == "Added Successfully" && callbackFun)
          callbackFun(data["Value"]["msg"]);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const getPatientCurrentVisitEMR = (MrNumber, VisitID, callbackFun) => {
    fetchStart();
    httpClient
      .get(
        `ClinicalAdministration/EmrHeading/GetEmrElementByVisit/${MrNumber},${VisitID}`
      )
      .then(({ data }) => {
        fetchSuccess(data);
        if (data != "No Record Found" && callbackFun) {
          callbackFun(data);
        } else if (callbackFun) {
          callbackFun([]);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const updateEMRData = (EmrDataId, callbackFun) => {
    fetchStart();
    httpClient
      .put(`DoctorTreatment/PatientEMR/PutEmrData/${EmrDataId}`)
      .then(({ data }) => {
        fetchSuccess(data);
        if (data["Value"]["msg"] == "Updated Successfully" && callbackFun)
          callbackFun(data["Value"]["Msg"]);
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  return {
    getAssignedTemplates,
    getAssignedMainGroup,
    getAssignedMainHeading,
    getAssignedHeading,
    getAssignedElement,
    addPatientEMR,
    getPatientCurrentVisitEMR,
    updateEMRData,
    isLoading,
    error,
    successMessage,
  };
};
